

import FacebookLogin from "react-facebook-login";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../Images/edit.png";
import { Form } from "reactstrap";
import {
  register,
  getCredentials,
  registerSocialLogin,
  registerSocialLoginTEMP,
  login,
  checkEternaviewCreated,
} from "../../redux/actions/index";
import connection from "../../config/connection";
import Loader from "react-loader";

const CustomFaceBookLogin=(props)=>{
    console.log(props, 'currentWERTY')
    const dispatch = useDispatch();

    let [email, setEmail] = useState("");
    let [emailError, setEmailError] = useState("");
    let [password, setPassword] = useState("");
    let [passwordError, setPasswordError] = useState("");
    let [inputtype, setInputType] = useState(false);
    let [loaded, setLoaded] = useState(true);

    const responseFacebook = (response) => {
        if(response.accessToken){

            setLoaded(false);
            const fbresponse = {
                Name: response.name,
                email: response.email ,
                token: response.id,
                ProviderId: response.graphDomain,
                accessToken:response.accessToken,
            };
      
            fbLogin(fbresponse)
        }
      
        setLoaded(false);
      }


const fbLogin = (payload) => {
          dispatch(getCredentials((res) => {
              console.log(res, 'abhishek')
              if (res.status == true) {
                let payload1={
                      "username": payload.email,
                      "client_id": res.res.client_id,
                      "client_secret": res.res.client_secret,
              }
        
                  dispatch(registerSocialLoginTEMP(payload1, (result) => {
                      console.log(result, 'AbhishResult')
                      setLoaded(true);
                      if (result.error) {
                        if(result.error == "Your account is inactive.Please check your email to activate your account."){
                            toast("Your account is inactive.Please check your email to activate your account. or contact us.", {
                                position: "top-right",
                                autoClose: 10000,
                                type: 'error',
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                            });
                            return
                        }
                          toast(result.error_description, {
                              position: "top-right",
                              autoClose: 10000,
                              type: 'error',
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: false,
                              progress: undefined,
                          });
                          if(!result.error_description){
                            toast(result.error, {
                                position: "top-right",
                                autoClose: 10000,
                                type: 'error',
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                            });
                          }
        
                      } else if (result.access_token) {
                          toast('You are successfully logged into application.', {
                              position: "top-right",
                              autoClose: 10000,
                              type: 'success',
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: false,
                              draggable: false,
                              progress: undefined,
                          });
                          sessionStorage.setItem("token",result.access_token)
                        //   props.props.history.push('/');
                        window.location.href = `${connection.homePage}?access_token=${result.access_token}`
                      }
                  }));
              }
          }));
        }

      const componentClicked=()=>{
      }
      
return(
<>
<FacebookLogin
// redirectUri="https://app.eternaview.com/oauth/sign-in"
disableMobileRedirect={true}
    // appId="" //NEW
    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}   //OLD
    autoLoad={true}
    fields="name,email,picture"
    callback={responseFacebook}
    icon="fa-facebook"
    textButton="Sign In with Facebook"
    onClick={componentClicked}
    scope='public_profile, email, user_birthday'
  /> 
</>
    )
}

export default CustomFaceBookLogin