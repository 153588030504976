import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import google from '../../Images/google.png'
import {
    LoginSocialGoogle,
  } from 'reactjs-social-login'
import GoogleLogin from "react-google-login";
import Elogo from '../../Images/wp_images/Eternaview-Logo1.png';
import FacebookLogin from "react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../Images/edit.png";
import { Form } from "reactstrap";
import {
    register,
    getCredentials,
    registerSocialLogin,
    login,
    checkEternaviewCreated,
    setNavigationBanner
} from "../../redux/actions/index";
import Loader from "react-loader";
import CustomFaceBookLogin from './CustomFaceBookLogin';
import connection from "../../config/connection";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { browserName, browserVersion } from "react-device-detect";


function SignIn(props) {
    const dispatch = useDispatch();
    const [redirUrl,setredirURL] = useState(null)
    let [email, setEmail] = useState('')
    let [emailError, setEmailError] = useState('')
    let [password, setPassword] = useState('')
    let [passwordError, setPasswordError] = useState('')
    let [inputtype, setInputType] = useState(false);
    let [loaded, setLoaded] = useState(true);
    const [clickedFb, setClickedFb] = useState(false);
    const auth = useSelector(state => state);
    const location =  useLocation()
    useEffect(() => {
        checkWPredirection()
        if (sessionStorage.getItem("token") && auth.auth.loginUserToken != "" && auth.auth.loginUserToken) {
            // props.history.push('/');
            window.location.href = connection.homePage + `?access_token=${auth.auth.loginUserToken}`
        }
        setInputType(false);
        setLoaded(true);
       // alert(browserName);
    }, []);

    const updateBannerDetails = (token) =>{
        dispatch(setNavigationBanner( {is_banner:true},token,(res)=>{
        }))
      }

    function showPassword() {
        setInputType(!inputtype);
    };

    function handleEmailInput(event) {
        setEmail(event.target.value)
        setEmailError(validate('email', event.target.value))
    };

    function handlePasswordInput(event) {
        setPassword(event.target.value)
        setPasswordError(validate('password', event.target.value))
    }

    function validate(name, value) {
        switch (name) {
            case "email":
                if (!value || value.trim() === "") {
                    return "Email is required.";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setLoaded(false);
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload = {
                    "username": email,
                    "password": password,
                    "grant_type": "password",
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret
                }
                dispatch(login(payload, (result) => {
                    setLoaded(true);
                    if (result.error == "invalid_grant") {
                        toast('Your email or password is incorrect. Please click the “forgot password” link if you would like reset your password or “Register Now” if you haven’t registered yet.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    } else if (result.access_token) {
                        dispatch(checkEternaviewCreated(result.access_token, (result) => {
                            console.log(result);
                        }));

                        toast('You are successfully logged into the application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        updateBannerDetails(result.access_token)
                        sessionStorage.setItem("token",result.access_token)

                        // props.history.push('/'); 
                        if(redirUrl == "practice"){
                            console.log(redirUrl,"<- redirUrl");
                            // alert("INSIDE")
                            props.history.push('/create/practice-test/')
                        }else{
                            window.location.href = `${connection.homePage}?access_token=${result.access_token}`
                        }
                       
                    } else {
                        toast(result.error, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                        return;
                    }
                }));
            }
        }));

    };

    const responseGoogle = (response) => {
        if (!response.error) {
            console.log('Logged In successfully with Google');
        } else {
            return;
        }

        setLoaded(false);
        const googleresponse = {
            Name: response.data.name,
            email: response.data.email,
            token: response.data.sub,
            Image: response.data.picture,
            ProviderId: "google",
          };
        socialLogin(googleresponse);
    }

    const responseGoogleFailure = (response) => {
        console.log(response, '>>>>>>responseGoogleFailure>>>>')
    }



    const socialLogin = (payload) => {
        dispatch(getCredentials((res) => {
            if (res.status == true) {
                let payload1 = {
                    "client_id": res.res.client_id,
                    "client_secret": res.res.client_secret,
                    "username": payload.email,
                    "unique_id": payload.token,
                    "platform": payload.ProviderId
                }
                dispatch(registerSocialLogin(payload1, (result) => {
                    setLoaded(true);
                    if (result.error) {
                        toast(result.error_description, {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'error',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });

                    } else if (result.access_token) {
                        toast('You are successfully logged into application.', {
                            position: "top-right",
                            autoClose: 10000,
                            type: 'success',
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                        });
                        updateBannerDetails(result.access_token)
                        sessionStorage.setItem("token",result.access_token)
                        // props.history.push('/');
                        if(redirUrl == "practice"){
                            props.history.push('/create/practice-test/')
                        }else{
                            window.location.href = `${connection.homePage}?access_token=${result.access_token}`
                        }
                    }
                }));
            }
        }));
    }

    const TriggerFb = () => {
        setClickedFb(true)
    }

    const checkWPredirection = () =>{
        const params = new URLSearchParams(location.search);
        const param_query = params.get('navto');
        setredirURL(param_query)
    }


    return (
        <section className="login_outer login67">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 offset-lg-3 offset-md-0">
                        <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
                            <div className="login_inner4 login_inner_new_design">
                                <h4 className="sign_in_title">Log in to<Link onClick={()=>{window.location.href = connection.homePage}}><img className="e-logo" src={Elogo} alt="elogo" /></Link></h4>
                                {/* <span className="etnat"> Eterna</span><span className="view_outre">view</span> */}
                                <div style={{cursor:"pointer",padding:9,display: browserName !='Facebook'? 'block': 'none'}} className="google_add">
                                    <LoginSocialGoogle
                                     client_id={process.env.REACT_APP_GOOGLE_ID}
                                        onResolve={responseGoogle}
                                        onReject={responseGoogleFailure}
                                    >
                                        <a  ><img src={google} />Sign In with Google</a>
                                    </LoginSocialGoogle>
                                    {/* <a href="#"><img src="assets/img/google.png" />Sign In with Google</a> */}
                                    {/* <GoogleLogin
                                        clientId="364132936223-1dm6pioltpokfbe9s7co6savjjlkd6n0.apps.googleusercontent.com"
                                        buttonText="Sign In with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogleFailure}>
                                    </GoogleLogin> */}
                                </div>
                                <div className="google_add fb_inner"  >
                                    {/* <a href="#"><img src="assets/img/facebook1.png" />Sign In with Facebook</a> */}
                                    {/*  <FacebookLogin
                                        appId=process.env.REACT_APP_FACEBOOK_CLIENT_ID
                                        textButton="Sign In with Facebook"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        icon="fa-facebook" /> */}

                                    {!clickedFb ? (
                                        <>
                                            <a onClick={TriggerFb} style={{ cursor: 'pointer' }}>
                                                <i className="fa fa-facebook" style={{ color: '#00008B', marginRight: '2px' }}></i>
                                                <span className="ml-2"> Sign In with Facebook</span></a>
                                        </>
                                    ) : null}
                                    {clickedFb && <CustomFaceBookLogin props={props} />}

                                </div>
                                
                                <p className="use_other_account"  style={{ display: browserName !='Facebook'? 'block': 'none'}}  >Or Use Your Email Account</p>

                                <div className="input_outer99">
                                    <input type="email" name="email" required placeholder="Email" value={email} onChange={handleEmailInput} />
                                </div>

                                <div className="input_outer99">
                                    {inputtype == false &&
                                        <input type="password" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                                    }
                                    {inputtype == true &&
                                        <input type="text" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                                    }

                                    {inputtype == false && <img src={edit} onClick={showPassword} />}
                                    {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                                </div>

                                <div className="checkbox custom_check">
                                    <label className="container98">Remember me
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                    <Link to="/oauth/forgot-password/">Forgot password?</Link>
                                </div>

                                <div className="log_outer">
                                    {/* <button type="submit">Log in</button> */}
                                    {loaded == true && <button className="Puchase_now_btn_new" type="submit">Log in</button>}
                                    {loaded == false && <button className="Puchase_now_btn_new" type="button">Logging in...</button>}

                                    <Loader loaded={loaded}></Loader>
                                </div>
                                <p className="new_user5">New User?<Link to={redirUrl ?`/oauth/sign-up/?navto=${redirUrl}`: "/oauth/sign-up/"}> Register Now</Link></p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>

    )
};

export default SignIn;

